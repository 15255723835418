<template>
  <v-card class="card-item" @click="$emit('save', item)">
    <v-card-text>
      <v-row no-gutters>
        <v-col cols="3" class="pt-2">
          <div class="pr-3 card-item__icon">
            <v-icon class="pa-2 pl-0" size="64">
              <slot name="icon"></slot>
            </v-icon>
          </div>
        </v-col>
        <v-col class="pt-6" style="padding-left: 20px;">
          <p class="mb-1" style="font-size: 20px;">
            <slot name="title"></slot>
          </p>
          <v-icon size="20" class="mb-1 mr-1">
            <slot name="subtitleIcon"></slot>
          </v-icon>
          <span>
            <slot name="subtitle"></slot>
          </span>
          <v-btn icon class="card-item__remove" @click.stop="$emit('delete', item)">
            <v-icon size="16">mdi-delete</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props:{
    item : Object
  }
}
</script>

<style scoped lang="scss">
.card-item {
  border-left: 3px solid var(--v-primary-base) !important;
  height: 140px;

  .card-item__icon {
    width: 85px;
    height: 80px;
  }

  .card-item__remove {
    position: absolute;
    top: 5px;
    right: 5px;
    display: none;
  }
   
  .card-item_buttons {
    width: 100%;
    display: inline-block;
  }
}
.card-item:hover .card-item__remove {
    display: block !important;
  }
</style>
