<template>
  <v-card>
    <v-form ref="form">
      <v-simple-table v-if="subscription">
        <thead>
          <tr>
            <th class="px-2">Meses</th>
            <!-- Only display headers for the days that have prices -->
            <th v-for="(day, index) in days" :key="'day-header-' + index" v-if="checkIfDisplay(index)">
              <v-radio-group v-model="selectedDays" dense hide-details class="mt-0">
                <v-radio :key="index + 1"
                         :label="`${index + 1 > 6 ? 'Pase libre' : index + 1} ${day <= 6 ? (day > 1 ? 'Días' : 'Día') : ''}`"
                         :value="index + 1"></v-radio>
              </v-radio-group>
            </th>
          </tr>
        </thead>
        
        <tbody>
          <tr v-for="(row, rowIndex) in subscription.priceMatrix" :key="'row-' + rowIndex">
            <td class="px-2">
              <v-radio-group v-model="selectedMonths" dense hide-details @change="emitSelection(row)">
                <v-radio :key="rowIndex" :label="row[0].months + ' Meses'" :value="row[0].months"></v-radio>
              </v-radio-group>
            </td>
            <td v-for="(cell, cellIndex) in row.slice(1)" :key="'cell-' + cellIndex" v-if="cell.price > 0"
                @click="selectDaysAndMonths(cellIndex, row)"
                class="text-center"
                v-bind:class="{ 'active': selectedDays === cellIndex + 1 && selectedMonths === row[0].months }">
              ${{ cell.price }}
            </td>
          </tr>
        </tbody>
      </v-simple-table>
    </v-form>
  </v-card>
</template>

<script>
import { getFirestore, collection, getDocs, query, where } from "firebase/firestore";

export default {
  data() {
    return {
      selectedDays: null,
      selectedMonths: null,
      selectedPrice: null,
      subscription: null, // Only one subscription in the array
      days: [1, 2, 3, 4, 5, 6, 'Libre'],
    };
  },
  mounted() {
    this.fetchSubscriptions();
  },
  methods: {
    async fetchSubscriptions() {
      const db = getFirestore();
      const querySnapshot = await getDocs(query(collection(db, "subscriptions"), where("userType", "==", 'usuario')));
      
      if (querySnapshot.docs.length) {
        const data = querySnapshot.docs[0].data();
        this.subscription = this.fromFirestore(data); // We only use the first subscription
      }
    },
    fromFirestore(serializedSubscription) {
      const deserializedMatrix = serializedSubscription.priceMatrix.map(row => [
        { months: row.months },
        ...row.prices.map(price => ({ price }))
      ]);
      return { ...serializedSubscription, priceMatrix: deserializedMatrix };
    },
    checkIfDisplay(dayIndex) {
      // Check if any row in the priceMatrix has a price for the given day index (cellIndex + 1)
      return this.subscription.priceMatrix.some(row => row[dayIndex + 1] && row[dayIndex + 1].price > 0);
    },
    selectDaysAndMonths(cellIndex, row) {
      this.selectedDays = cellIndex + 1;
      this.selectedMonths = row[0].months;
      this.selectedPrice = row[cellIndex + 1].price;
      this.emitSelection();
    },
    emitSelection() {
      if (this.selectedDays && this.selectedMonths) {
        const price = this.selectedPrice;
        this.$emit('subscription-selected', { days: this.selectedDays, months: this.selectedMonths, price });
      }
    }
  }
};


</script>



<style scoped>

/*  active class, hover per td element on table too, and when overing the td cursor pointer*/


.theme--dark table tbody tr td.active {
  background-color: #424242;
}

.theme--dark table tbody tr td:hover {
  background-color: #424242;
    cursor: pointer;

}

.theme--dark  table tbody tr td.active:hover {
  cursor: pointer;
}

/* light */

.theme--light table tbody tr td.active {
  background-color: #f5f5f5;
}

.theme--light table tbody tr td:hover {
  background-color: #f5f5f5;
    cursor: pointer;

}

.theme--light  table tbody tr td.active:hover {
  cursor: pointer;
}
</style>